'use client'

import { useState } from 'react'

import { usePlans } from '@/hooks/usePlans'

import { If, NewModal, Plan } from '@/components'

import { PaymentDetails } from './components'

import { PAYMENT_DETAILS } from './constants'

import styles from './PlanList.module.scss'

export const PlanList = ({
  children,
  selectedPlan,
  setSelectedPlan,
  onClickCTA
}) => {
  const [isPaymentDetailsOpen, setIsPaymentDetailsOpen] = useState(false)
  const [expandedPlans, setExpandedPlans] = useState({})

  const { postpaidPlans } = usePlans()

  return (
    <>
      <If condition={isPaymentDetailsOpen}>
        <NewModal onClose={() => setIsPaymentDetailsOpen(false)}>
          <div className={styles.plans__paymentDetails}>
            {PAYMENT_DETAILS.map(({ example, results }) => (
              <div key={Math.random()} className={styles.plans__paymentDetail}>
                <PaymentDetails details={example} />

                {results && (
                  <PaymentDetails type="result" color="red" details={results} />
                )}
              </div>
            ))}
          </div>
        </NewModal>
      </If>

      <div className={styles.plans}>
        {postpaidPlans.map((plan) => (
          <Plan
            plan={plan}
            key={`plans-${plan.id}`}
            selectedPlan={selectedPlan || 'advanced'}
            expandedPlans={expandedPlans}
            setExpandedPlans={setExpandedPlans}
            openPaymentDetails={() => setIsPaymentDetailsOpen(true)}
            onClickCard={setSelectedPlan}
            onClickCTA={onClickCTA}
          >
            {children}
          </Plan>
        ))}
      </div>
    </>
  )
}
