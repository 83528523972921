import { Icon } from '..'

import * as styles from './HighlightTitle.module.scss'

export const HighlightTitle = ({ icon, text }) => {
  return (
    <div className={styles.highlightTitle}>
      <Icon name={icon} size="small" color="primary" />
      <p>{text}</p>
    </div>
  )
}
